import { PureComponent } from "react";

export default class Server extends PureComponent {
  static Session_check(){
    if (Date.parse(new Date()) - Date.parse(localStorage.timestamp) > 8 * 60 * 60 * 1000) {
      localStorage.removeItem("footprint");
      localStorage.removeItem("timestamp");
      window.location.href="/"
    }
  }
}