import React, { PureComponent } from "react";
import { ClassicSpinner } from "react-spinners-kit";
import { Award, User, Calendar } from "react-feather";
import { Link } from "react-router-dom";

import axios from "axios";
import Server from "../../routes/server";

export default class dashboard extends PureComponent {
  state = {
    requesting: true,
    dashboard_data: [],
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(process.env.REACT_APP_Server + "/dashboard/admin/data", config)
      .then((rsp) => {
        this.setState({
          dashboard_data: rsp.data,
          requesting: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
            requesting: false,
          });
        }
      });
  };
  render() {
    const { dashboard_data } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Dashboard</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Link to="/users" className="card_link text-decoration-none">
              <div className="card lift">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="text-uppercase text-muted mb-2 font-weight-bold">
                        Total User
                      </p>
                      <span className="h3 mb-0">
                        {this.state.requesting ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color="#000000"
                            loading={true}
                          />
                        ) : (
                          dashboard_data.users
                        )}
                      </span>
                    </div>
                    <div className="col-auto mr-4">
                      <User />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/events" className="card_link text-decoration-none">
              <div className="card lift">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="text-uppercase text-muted mb-2 font-weight-bold">
                        Total Events
                      </p>
                      <span className="h3 mb-0">
                        {this.state.requesting ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color="#000000"
                            loading={true}
                          />
                        ) : (
                          dashboard_data.events
                        )}
                      </span>
                    </div>
                    <div className="col-auto mr-4">
                      <Calendar />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/credentials" className="card_link text-decoration-none">
              <div className="card lift">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="text-uppercase text-muted mb-2 font-weight-bold">
                        Total Published Credentials
                      </p>
                      <span className="h3 mb-0">
                        {this.state.requesting ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color="#000000"
                            loading={true}
                          />
                        ) : (
                          dashboard_data.publish_credentials
                        )}
                      </span>
                    </div>
                    <div className="col-auto mr-4">
                      <Award />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/credentials" className="card_link text-decoration-none">
              <div className="card lift">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="text-uppercase text-muted mb-2 font-weight-bold">
                        Total Unpublished Credentials
                      </p>
                      <span className="h3 mb-0">
                        {this.state.requesting ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color="#000000"
                            loading={true}
                          />
                        ) : (
                          dashboard_data.unpublish_credentials
                        )}
                      </span>
                    </div>
                    <div className="col-auto mr-4">
                      <Award />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </main>
    );
  }
}
