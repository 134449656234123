import React, { PureComponent } from 'react'
import axios from "axios";
import Server from "../../routes/server";
import Skeleton from 'react-loading-skeleton';
import {ArrowLeft,ArrowRight, Search,CheckCircle, XCircle} from "react-feather"


export default class Events extends PureComponent {
    state = {
        requesting: true,
        cred_list: [],
        tot_data:null,
        next:null,
        prev:null,
        items: Array.from({ length: 10 }),
        search_query:'',
        filter_type:0,
      };

    componentDidMount(){
        this.getData(this.state.filter_type,this.state.search_query)
    }
    getData=(filter,search,is_pagination,url)=>{
        console.log(search)
        const token = localStorage.getItem("footprint");
        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };
        var final_url
        if(is_pagination){
            final_url = url
        }
        else{
            
            final_url=process.env.REACT_APP_Server + "/event/admin/events?search="+search+"&filter="+filter
            console.log(final_url)
        }
        axios
          .get(final_url, config)
          .then((rsp) => {
            console.log(rsp)
            this.setState({
              cred_list: rsp.data.results,
              tot_data:rsp.data.count,
              requesting: false,
              next:rsp.data.next,
              prev:rsp.data.previous

            });
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.setState({
                requesting: false,
              });
            }
          });
    }
    handlepagnation=(url)=>{
        if(process.env.REACT_APP_environment == "PROD"){
            var url = url.replace("https", "http");
            var url = url.replace("http", "https");
        }
        this.getData("","","True",url)

    }
    filter=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
        this.getData(e.target.value,this.state.search_query)
    }
    onchangesearch=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    search=(e)=>{
        e.preventDefault();
        this.getData(this.state.filter_type,this.state.search_query)

    }
    render() {
        const { cred_list,tot_data,next,prev,requesting } = this.state
        return (
            <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
                <div className="card mt-4 shadow-sm">
                    <div className="d-flex justify-content-between p-3">
                        <h3 className="card-title h5 mb-0 mt-1">Credentials</h3>
                    </div>
                </div>
                <div className="card mt-4 shadow-sm">
                    <div className="card-body p-0 border-0">
                        {/* <div className="row justify-content-end">
                        <div class="col-md-2 mt-2">
                                <input type="text" class="form-control text-muted rounded-custom pl-3" placeholder="search" required=""/>
                            </div>
                            <div class="col-md-4 mr-3 mt-2">
                                <input type="text" class="form-control text-muted rounded-custom pl-3" placeholder="search" required=""/>
                            </div>
                        </div> */}
                        <ul className="pagination justify-content-end mr-3 mt-4">
                            <li className="list-item">
                                <form className="form-inline" onSubmit={this.search}>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="search_query"
                                        id="dearch"
                                        placeholder="Search by email or username..."
                                        onChange={this.onchangesearch}
                                    />
                                    <div className="input-group-append">
                                    <button className="btn btn-primary">
                                        <Search/>
                                    </button>
                                    </div>
                                </div>
                                </form>
                            </li>
                            {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
                            <li className="list-item">
                                <select className="form-control" name="filter" onChange={this.filter} defaultValue="0">
                                <option value="0">All</option>
                                <option value="1">Published</option>
                                <option value="2">Unpublished</option>
                                </select>
                            </li>
                        </ul>
                        <div className="table-responsive px-3">
                            <table className="table table-striped table-hover mb-0 border">
                                <thead className="border">
                                <tr className="text-center">
                                    <th>Event Id</th>
                                    <th>Created By</th>
                                    <th>public_name</th>
                                    <th>private_name</th>
                                </tr>
                                </thead>
                                <tbody>
                                {requesting ? 
                                this.state.items.map((x, index) => 
                                    <tr className="text-center" key={index}>
                                    <td><Skeleton width="90%"/></td>
                                    <td><Skeleton width="90%"/></td>
                                    <td><Skeleton width="90%"/></td>
                                    <td><Skeleton width="90%"/></td>
                                    <td><Skeleton width="90%"/></td>
                                    <td><Skeleton width="90%"/></td>
                                    </tr>
                                ) :
                                cred_list.map((data, index) => 
                                (<tr  className="text-center" key={index}>
                                    <td>
                                    
                                    {data.event_id}&nbsp;
                                    {data.publish ? 
                                        <CheckCircle color="green"/> 
                                        : 
                                        <XCircle color="red"/> 
                                        
                                    }
                                    </td>
                                    <td>
                                        {data.event_created_by.email}
                                    </td>
                                    <td>
                                        {data.public_name}
                                    </td>

                                    <td>
                                    {data.private_name}
                                    </td>
                                </tr>
                                ))}
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                
                    <div className="card-footer bg-transparent border-0 p-3">
                        <div className="row">
                            <div className="col-6 text-muted">
                                {requesting ? <Skeleton width="50%"/> :
                                    <h6 className="h6 pt-2 small">Showing {cred_list.length} of {tot_data}</h6>
                                }
                            </div>

                            <div className="col-6 text-right">
                                <button
                                    className="btn btn-white btn-sm lift rounded-pill border px-2 mr-1"
                                    onClick={this.handlepagnation.bind(this,prev)}
                                    disabled={prev ? false : true}
                                    >
                                    
                                    <ArrowLeft/>{" "} Prev
                                    </button>
                                    <button
                                    className="btn btn-white btn-sm lift rounded-pill border px-2"
                                    onClick={this.handlepagnation.bind(this,next)}
                                    disabled={next ? false : true}
                                    >
                                    
                                    Next{" "}
                                    <ArrowRight/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}
