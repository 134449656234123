import React, { PureComponent } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

export default class Sidebar extends PureComponent {
  state = {
    active_tab: window.location.href.split("/")[3],
  };

  componentDidMount = () => {
    document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/light-theme.css");
  };

  set_active = (tab) => {
    this.setState({
      active_tab: tab,
    });
    document.getElementById("sidebarMenu").classList.remove("show");
  };

  logout = () => {
    localStorage.removeItem("footprint");
    window.location.href = "/";
  };

  render() {
    const {  active_tab } = this.state;
    return (
      <nav
        id="sidebarMenu"
        className="col-md-3 col-lg-2 d-md-block sidebar collapse pt-5 shadow-sm"
      >
        <div className="text-center d-none d-md-block">
          <a className="navbar-brand" href="/">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/logo/certifidoc_logo.png`}
              height="50"
              width="100"
              className="navbar-brand-img mx-auto"
              alt="Wilyer Certifidoc"
            />
          </a>
        </div>
        <div className="position-sticky pt-5">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                to=""
                className={active_tab === "" ? "nav-link active" : "nav-link"}
                aria-current="page"
                onClick={() => this.set_active("")}
              >
                <Icon.Home size={14} /> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/users"
                className={
                  active_tab === "users" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("users")}
              >
                <Icon.User size={14} /> All User
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/credentials"
                className={
                  active_tab === "credentials" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("credentials")}
              >
                <Icon.Award size={14} /> Credentials
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/events"
                className={
                  active_tab === "events" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("events")}
              >
                <Icon.Calendar size={14} /> Events
              </Link>
            </li>
          </ul>

          {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Documentation</span>
            <span className="link-secondary">
              <Icon.Book size={14} />
            </span>
          </h6> */}
          {/* <ul className="nav flex-column mb-2">
            <li className="nav-item">
              <Link
                to="/faqs"
                className={
                  active_tab === "faqs" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("faqs")}
              >
                <Icon.AlertOctagon size={14} /> FAQs
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/help"
                className={
                  active_tab === "help" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("help")}
              >
                <Icon.HelpCircle size={14} /> Help?
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/cert-design-guide"
                className={
                  active_tab === "cert-design-guide" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("cert-design-guide")}
              >
                <Icon.Grid size="14"/> Certificate Guide
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className={
                  active_tab === "contact" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("contact")}
              >
                <Icon.Mail size={14} /> Contact Us
              </Link>
            </li>
          </ul> */}
          <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Account</span>
            <span className="link-secondary">
              <Icon.User size={14} />
            </span>
          </h6>
          <ul className="nav flex-column mb-2">
            {/* <li className="nav-item">
              <Link
                to="/settings"
                className={
                  active_tab === "settings" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("settings")}
              >
                <Icon.Settings size={14} /> Settings
              </Link>
            </li> */}
            <li className="nav-item">
              <a
                href="#!"
                className="nav-link text-danger"
                onClick={this.logout}
                role='button'
              >
                <Icon.Power size={14} color="#dc3545" /> Logout
              </a>
            </li>
          </ul>
          {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Releases</span>
            <a
              className="link-secondary"
              href="javascipt:void(0)"
              aria-label="Add a new report"
            >
              <Icon.Database size={14} />
            </a>
          </h6>
          <ul className="nav flex-column mb-2">
            <li className="nav-item">
              <Link
                to="/updates"
                className={
                  active_tab === "updates" ? "nav-link active" : "nav-link"
                }
                onClick={() => this.set_active("updates")}
              >
                <Icon.Package size={14} /> Updates
              </Link>
            </li>
          </ul> */}
        </div>
      </nav>
    );
  }
}
