import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import dashboard from "../source/dashboard/dashboard";
import Users from "../source/dashboard/users";
import Credential from "../source/dashboard/credential";
import Events from "../source/dashboard/events";



export default class Dashboard_Router extends PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/users" component={Users} />
        <Route path="/credentials" component={Credential} />
        <Route path="/events" component={Events} />
        <Route path="/" component={dashboard} />
      </Switch>
    );
  }
}
