import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import login from "../source/auth/login";

export default class Auth_Router extends PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/" component={login} />
      </Switch>
    );
  }
}
