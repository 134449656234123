import React, { PureComponent } from "react";
import axios from "axios";
import Server from "../../routes/server";
import Skeleton from "react-loading-skeleton";
import Modal from "../../comp/modal";
import {
  ArrowLeft,
  ArrowRight,
  CheckCircle,
  XCircle,
  Search,
  LogIn,
} from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClassicSpinner } from "react-spinners-kit";

export default class Users extends PureComponent {
  state = {
    requesting: true,
    user_list: [],
    tot_data: null,
    next: null,
    prev: null,
    items: Array.from({ length: 10 }),
    user_id: null,
    index: null,
    search_query: "",
  };

  componentDidMount() {
    this.getData();
  }
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };
  getData = (url, is_pagination, search = "") => {
    console.log(url);
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    var final_url;
    if (is_pagination) {
      final_url = url;
    } else {
      final_url =
        process.env.REACT_APP_Server + "/user/admin/users?search=" + search;
    }
    axios
      .get(final_url, config)
      .then((rsp) => {
        console.log(rsp.data);
        this.setState({
          user_list: rsp.data.results,
          tot_data: rsp.data.count,
          requesting: false,
          next: rsp.data.next,
          prev: rsp.data.previous,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
            requesting: false,
          });
        }
      });
  };
  handlepagnation = (url) => {
    if (process.env.REACT_APP_environment == "PROD") {
      var url = url.replace("https", "http");
      var url = url.replace("http", "https");
    }
    this.getData(url, true);
  };

  activate = () => {
    this.setState({ loading: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const param = new URLSearchParams();
    param.append("user_id", this.state.user_id);
    axios
      .post(
        process.env.REACT_APP_Server + "/user/admin/activate-email",
        param,
        config
      )
      .then((rsp) => {
        console.log(rsp);
        this.setState({ loading: false });
        this.setState((prevState) => {
          const newItems = [...prevState.user_list];
          newItems[this.state.index].is_active = true;
          return { items: newItems };
        });
        var myModal = document.getElementById("model_active_close").click();
        this.notify("Account has been activated..", "success");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ loading: false });
          var myModal = document.getElementById("model_active_close").click();
          this.notify("Some error occured. Please try again.", "error");
        }
      });
  };
  set_user = (id, index) => {
    this.setState({
      user_id: id,
      index: index,
    });
  };
  onchangesearch = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  search = (e) => {
    e.preventDefault();
    this.getData("", false, this.state.search_query);
  };
  master_login = (email) => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const param = new URLSearchParams();
    param.append("email", email);
    axios
      .post(
        process.env.REACT_APP_Server + "/user/admin/master-login",
        param,
        config
      )
      .then((rsp) => {
        // console.log(rsp.data['token'])
        window.open(
          "https://dashboard.certifidoc.com/master/" + rsp.data["token"],
          "_blank"
        );
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  verification = (user_id, type) => {
    const params = {
      type,
    };
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .post(
        process.env.REACT_APP_Server + "/user/admin/user-verification/" + user_id,
        params,
        config
      )
      .then((rsp) => {
        alert(rsp.data.detail);
      })
      .catch((err) => {
        alert(String(err.response.data.detail));
      });
  };

  render() {
    const { user_list, tot_data, next, prev, requesting } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Users</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-0 border-0">
            <ul className="pagination justify-content-end mr-3 mt-4">
              <li className="list-item">
                <form className="form-inline" onSubmit={this.search}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="search_query"
                      id="dearch"
                      placeholder="Search by email"
                      onChange={this.onchangesearch}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary">
                        <Search />
                      </button>
                    </div>
                  </div>
                </form>
              </li>
            </ul>
            <div className="table-responsive px-3">
              <table className="table table-striped table-hover mb-0 border">
                <thead className="border">
                  <tr className="text-center">
                    <th>User Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile No.</th>
                    <th>Org Name</th>
                    <th>Joined</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {requesting
                    ? this.state.items.map((x, index) => (
                        <tr className="text-center" key={index}>
                          <td>
                            <Skeleton width="90%" />
                          </td>
                          <td>
                            <Skeleton width="90%" />
                          </td>
                          <td>
                            <Skeleton width="90%" />
                          </td>
                          <td>
                            <Skeleton width="90%" />
                          </td>
                          <td>
                            <Skeleton width="90%" />
                          </td>
                          <td>
                            <Skeleton width="90%" />
                          </td>
                        </tr>
                      ))
                    : user_list.map((data, index) => (
                        <tr key={index} className="text-center">
                          <td>{data.user_id}</td>
                          <td>{data.user_fullname}</td>
                          <td>
                            {data.email} &nbsp;
                            {data.is_active ? (
                              <CheckCircle color="green" />
                            ) : (
                              <a
                                href="#!"
                                // onClick={() => this.setState(navStatus: "navShow")}
                                onClick={() =>
                                  this.set_user(data.user_id, index)
                                }
                                data-toggle="modal"
                                data-target="#model_active"
                              >
                                <XCircle color="red" />
                              </a>
                            )}
                          </td>
                          <td>{data.user_contact_number}</td>
                          <td>{data.org_name}</td>
                          <td>
                            {new Date(data.date_joined).toLocaleString(
                              undefined,
                              {
                                timeZone: "Asia/Kolkata",
                              }
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm mr-1"
                              onClick={this.master_login.bind(this, data.email)}
                            >
                              <LogIn size={15} />
                            </button>
                            {!data.is_verified ? (
                              <button
                                className="btn btn-default border-success btn-sm mr-1"
                                onClick={() =>
                                  this.verification(data.user_id, "verified")
                                }
                              >
                                <img
                                  src="/assets/img/verified.svg"
                                  height="20"
                                  alt=""
                                />
                              </button>
                            ) : (
                              <button
                                className="btn btn-default border-danger btn-sm"
                                onClick={() =>
                                  this.verification(
                                    data.user_id,
                                    "not verified"
                                  )
                                }
                              >
                                <img
                                  src="/assets/img/warning.svg"
                                  height="20"
                                  alt=""
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                {requesting ? (
                  <Skeleton width="50%" />
                ) : (
                  <h6 className="h6 pt-2 small">
                    Showing {user_list.length} of {tot_data}
                  </h6>
                )}
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm lift rounded-pill border px-2 mr-1"
                  onClick={this.handlepagnation.bind(this, prev)}
                  disabled={prev ? false : true}
                >
                  <ArrowLeft /> Prev
                </button>
                <button
                  className="btn btn-white btn-sm lift rounded-pill border px-2"
                  onClick={this.handlepagnation.bind(this, next)}
                  disabled={next ? false : true}
                >
                  Next <ArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          key="model_active"
          id="model_active"
          title="Active Account!"
          close_id="model_active_close"
        >
          <div className="modal-body text-center p-4 mb-3">
            <h5>Are you sure?</h5>

            <div className="mt-4">
              <button
                className="btn btn-danger  rounded-pill rounded-lg"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                className="btn btn-success rounded-pill ml-2"
                onClick={this.activate}
              >
                Yes{" "}
                {this.state.loading ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color="#fff"
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </Modal>
        <ToastContainer />
      </main>
    );
  }
}
