import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Server from "../../routes/server";
import { Eye, EyeOff } from "react-feather";
import axios from 'axios';
import Recaptcha from 'react-google-invisible-recaptcha';



export default class Login extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      show_password_icon: <Eye size={14} />,
      password_type: "password",
      confirm_email: false,
      email:'',
      password:'',
      alertClass:'d-none',
      alertMessage:'',
      closeClass:'d-none',
      btn_loading:false,
      second:0,
      reCaptcha :"asbin"
    };
    
  }
  
  show_Password = () => {
    const { password_type } = this.state;
    this.setState({
      show_password_icon:
        password_type === "password" ? <EyeOff size={14} /> : <Eye size={14} />,
      password_type: password_type === "password" ? "text" : "password",
    });
  };

  onTextChange=(e)=>{
    this.setState({ [e.target.name]: e.target.value });
  }

  submitHandler=async(e)=>{
    e.preventDefault();
    await this.recaptcha.execute();
    this.setState({btn_loading:true})
    const paramsss = new URLSearchParams();
    paramsss.append("username", this.state.email);
    paramsss.append("password", this.state.password);
    paramsss.append("g-recaptcha-response", this.state.reCaptcha)
    axios
      .post(process.env.REACT_APP_Server + "/user/admin/login", paramsss)
      .then((rsp) => {
        console.log(rsp)
        if (
          rsp.data.user.is_superuser ||
          rsp.data.user.is_admin
        ){
          this.setState({
            alertMessage:"Login successful as admin, redirecting...",
            alertClass:"alert alert-success rounded-lg alert-dismissible",
            btn_loading:false
          })
          localStorage.setItem("footprint", rsp.data.token);
          localStorage.setItem("timestamp", new Date());
          window.location.href = "/";
        }
        else{
          this.setState({
            alertMessage:"Email or Password is incorrect!",
            alertClass:"alert alert-danger rounded-lg alert-dismissible",
            btn_loading:false
          });
        }
        
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          if (error.response.data.hasOwnProperty("is_activate")) {
            this.setState({
              confirm_email:true,
              btn_loading:false
            });
            this.generate_otp();
          }
          else {
            this.setState({
              alertMessage:"Email or Password is incorrect!",
              alertClass:"alert alert-danger rounded-lg alert-dismissible",
              
              btn_loading:false
            });
          }
        }
      });
  }
  generate_otp = async() => {
    await this.recaptcha.execute();
    const paramss = new URLSearchParams();
    paramss.append("email", this.state.email);
    paramss.append("g-recaptcha-response", this.state.reCaptcha)
    axios
      .post(process.env.REACT_APP_Server + "/user/generate-otp", paramss)
      .then((rsp) => {
        this.setState({
          alertMessage: "OTP has been sent, please check you email for OTP",
          alertClass:"alert alert-success rounded-lg alert-dismissible",
          
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
          alertMessage: "OTP could not be sent. Please try again later.",
          alertClass:"alert alert-danger rounded-lg alert-dismissible",
          
        });
        }
      });
  };

  auth_otp=async(e)=> {
    e.preventDefault();
    await this.recaptcha.execute();
    this.setState({btn_loading:true})
      const params = new URLSearchParams();
      params.append("user_email", this.state.email);
      params.append("user_otp", this.state.otp);
      params.append("g-recaptcha-response", this.state.reCaptcha)
      
      axios
        .post(process.env.REACT_APP_Server + "/user/auth-otp", params)
        .then((rsp) => {
          this.activation(rsp.data.user_token);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            this.setState({
              alertMessage: "Invalid OTP",
              alertClass:"alert alert-danger rounded-lg alert-dismissible",
              
              btn_loading:false,
            });
          }
    });
  }

  activation = (token) => {
    const params = new URLSearchParams();
    params.append("user_token", token);
    axios
      .post(process.env.REACT_APP_Server + "/user/activation", params)
      .then((rsp) => {
        this.setState({
          alertMessage: "Your account has been varified. Please login...",
          alertClass:"alert alert-success rounded-lg alert-dismissible",
          
          btn_loading:false,
          confirm_email:false,
        })
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({btn_loading:false})
          
        }
      });
  };

  resend_otp = () => {
    this.setState({ spinner_loader: true, second: 30 });
    const paramsss = new URLSearchParams();
    paramsss.append("email", this.state.email);
    axios
      .post(process.env.REACT_APP_Server + "/user/generate-otp", paramsss)
      .then((rsp) => {
        this.setState({ second: 30, spinner_loader: false });
        this.setState({
          alertMessage: "OTP has been sent. Please check your email.",
          alertClass:"alert alert-success rounded-lg alert-dismissible",
          
        });
        this.myInterval = setInterval(() => {
          if (this.state.second > 0) {
            this.setState(({ second }) => ({
              second: second - 1,
            }));
          } else {
            clearInterval(this.myInterval);
          }
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
            second: "0",
            spinner_loader: false,
            alertMessage: "OTP could not be sent. Please try again later.",
            alertClass:"alert alert-danger rounded-lg alert-dismissible",
            
          });
        }
      });
  
};

onResolved=()=> {
  this.setState({reCaptcha:this.recaptcha.getResponse()})
}


  render() {
    const { show_password_icon, password_type, confirm_email,alertMessage,alertClass,closeClass,btn_loading,second } = this.state;
    return (
      <div style={{  
        backgroundImage: "url(assets/img/auth_page_background.png)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
       className="wilyer-container">
        <div className="wilyer-signin">
          <div className="card rounded-xl mt-4 shadow-sm">
            <div className="text-center p-5 pb-0">
              <img
                src="assets/img/logo/certifidoc_logo.png"
                height="auto"
                width="40%"
                className="navbar-brand-img mx-auto"
                alt="Wilyer CertifiDoc Logo"
              />
              <div className="h4 pt-3">
                {!confirm_email
                  ? "Welcome Back, Please Login"
                  : "Confirm email address"}
              </div>
            </div>
            <div className="card-body p-5">
              <div>
                <div
                  className={alertClass}
                  role="alert"
                >
                  {alertMessage}
                  <span className={closeClass} data-dismiss="alert">
                    <span aria-hidden="true">&times;</span>
                  </span>
                </div>
              </div>
              {!confirm_email ? (
                <form onSubmit={this.submitHandler}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control rounded-lg"
                      autoFocus={true}
                      required
                      onChange={this.onTextChange}
                    />
                  </div>
                  <div className="form-group pt-4">
                    <label htmlFor="username">Password</label>
                    <div className="input-group">
                      <input
                        type={password_type}
                        name="password"
                        id="password"
                        className="form-control password-field"
                        required
                        onChange={this.onTextChange}
                      />
                      <span
                        type="button"
                        className="input-group-text bg-default"
                        onClick={this.show_Password}
                      >
                        {show_password_icon}
                      </span>
                    </div>
                  </div>
                  <div className="form-group pt-4">
                    <button className="btn btn-primary form-control rounded-lg" type="submit">
                      Log In {" "}
                      {btn_loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:""}
                    </button>
                  </div>
                </form>
                
              ) : (
                // Confirm email
                <form onSubmit={this.auth_otp}>
                  <div className="form-group">
                    <label htmlFor="otp">OTP</label>
                    <input
                      type="number"
                      name="otp"
                      id="otp"
                      onChange={this.onTextChange}
                      className="form-control rounded-lg"
                      autoFocus={true}
                      required
                    />
                  </div>
                  <div className="form-group pt-4">
                    <button className="btn btn-primary form-control rounded-lg">
                      Confirm {" "}
                      {btn_loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:""}
                    </button>
                    <br />
                    <p className="pt-3 pb-0 text-center">
                      <small>
                        Go back to
                        <Link to="/" className="ml-1 text-decoration-none">
                          Log In
                        </Link>
                      </small>
                      <span className="pl-2 pr-2">|</span>
                      <small>
                        Did't get OTP?
                        {this.state.spinner_loader ? (
                          <div
                            className="spinner-border spinner-border-sm text-primary ml-2"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : this.state.second === 0 ? (
                          <a href="/#" onClick={this.resend_otp}>
                            {" "}
                            Resend Now
                          </a>
                        ) : (
                          " Resend OTP in " + second + " seconds"
                        )}
                      </small>
                    </p>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
          <Recaptcha
          ref={ ref => this.recaptcha = ref }
          sitekey={process.env.REACT_APP_SITE_KEY}
          onResolved={ this.onResolved } />
      </div>
    );
  }
}
