import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Topbar from "./components/header/topbar";
import Sidebar from "./components/header/sidebar";
import AuthRouter from "./components/routes/auth";
import DashboardRouter from "./components/routes/dashboard";
function App() {
  if (Date.parse(new Date()) - Date.parse(localStorage.timestamp) > 8 * 60 * 60 * 1000) {
    localStorage.removeItem("footprint");
    localStorage.removeItem("timestamp");
  }
  let login = localStorage.getItem("footprint") === null ? false : true;
  // var login = false;
  return (
    <Fragment>
      <BrowserRouter>
        {
          login ?
          (
            <Fragment>
              <Topbar />
              <div className="container-fluid">
                <div className="row">
                  <Sidebar />
                  <Switch>
                    <Route path="/" component={DashboardRouter} />
                  </Switch>
                </div>
              </div>
            </Fragment>
          ) 
          : 
          (
            <Fragment>
              <Switch>
                <Route path="/" component={AuthRouter} />
              </Switch>
            </Fragment>
          )
        }
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
